<script>
import axios from "axios";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ApplicationCreate",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      activeStep: 0,
      categories: [],
      categoryDocuments: [],
      selectedCategory: null,
      files: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    getCategoryList() {
      axios.get("categories").then((res) => {
        this.categories = res.data;
      });
    },
    getCategoryDocumentList() {
      axios
        .get(`categories/${this.selectedCategory.id}/documents`)
        .then((res) => {
          this.categoryDocuments = res.data;
        });
    },
    onCategorySelect() {
      this.activeStep = 1;

      this.getCategoryDocumentList();
    },

    onFormSubmit() {
      this.isLoading = true;
      var formData = new FormData();

      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i] !== undefined) {
          formData.append(i, this.files[i]);
        }
      }

      formData.append("category_id", this.selectedCategory.id);

      axios.post("applications", formData).then((res) => {
        this.isLoading = false;
        this.$buefy.toast.open({
          message: this.$t("application_sent_successfully"),
          type: "is-success",
          position: "is-top-right",
        });
        this.$router.push({
          name: "StudentApplicationDetails",
          params: {
            id: res.data.application.id,
          },
        });
      });
    },
  },
};
</script>

<template>
  <div>
    <h1 class="has-text-centered mb-4 is-size-4">
      {{ $t("send_application") }}
    </h1>
    <!-- STEP ONE -->
    <section v-if="activeStep === 0">
      <b-field class="has-text-centered">
        <b-select
          :placeholder="$t('select_category')"
          v-model="selectedCategory"
          @input="onCategorySelect()"
        >
          <option
            v-for="category in categories"
            :value="category"
            :key="category.id"
          >
            {{ localize(category, "title") }}
          </option>
        </b-select>
      </b-field>
    </section>
    <!-- STEP TWO -->
    <section v-else-if="activeStep === 1" class="files-upload-form">
      <p class="has-text-centered pb-5">
        {{ localize(selectedCategory, "description") }}
      </p>

      <ValidationObserver v-slot="{ invalid }">
        <div
          v-for="document in categoryDocuments"
          v-bind:key="document.id"
          class="mb-3"
        >
          <ValidationProvider
            :name="localize(document, 'title')"
            :rules="document.is_necessary ? 'required' : '' + 'ext:pdf,jpg,jpeg,png'"
            immediate
            v-slot="{ valid }"
            slim
          >
            <b-field class="file is-primary">
              <b-upload v-model="files[document.id]" drag-drop expanded>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>
                      <b class="has-text-centered">
                        {{ localize(document, "title") }}
                      </b>
                      <br />
                      <span class="help">{{
                        $t("drag_file_here_or_click_to_upload")
                      }}</span>
                      <br />
                      <template v-if="files[document.id]">
                        {{ $t("selected_file") }}: {{ files[document.id].name }}
                      </template>
                      <template v-if="!valid">
                        <br />
                        <span
                          class="help is-danger"
                          v-if="document.is_necessary"
                        >
                          {{ $t("file_is_required") }}
                        </span>
                      </template>
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </ValidationProvider>
        </div>
        <b-button
          class="is-fullwidth"
          type="is-primary"
          native-type="submit"
          @click="onFormSubmit()"
          :disabled="invalid || isLoading"
        >
          {{ $t("send") }}
        </b-button>
      </ValidationObserver>
    </section>
  </div>
</template>

<style lang="scss">
.files-upload-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
}
</style>
